/**
 * Contact Form v1.0.0
 */

(function ($) {

  // contact Form Page
  var contactForm = $('#contact-form');
  var contactFormSubmitButton = contactForm.find(':submit');

  // jQuery AJAX form
  var cf = contactForm.ajaxForm({
    beforeSubmit: function (arr, $form, options) {
      var filled = arr.every(function (input) {
        return input.value !== '';
      });

      if (filled) {
        contactFormSubmitButton.attr('disabled', true);
      }
    },

    success: function (response) {
      // Reset submit button
      contactFormSubmitButton.attr('disabled', false);

      // If success
      if (response.success === true) {
        $('#alert-submission', contactForm).show();
        setTimeout(function () { $('#alert-submission', contactForm).hide() }, 5000);

        cf.clearForm();
        document.getElementById('alert-submission', contactForm).scrollIntoView();

        // Add conversion tag here.
      }

      // Name
      if (response.success === false && response.data.name) {
        $('input#name', contactForm).addClass('is-invalid');
        $('#alert-name', contactForm).text(response.data.name);

        $('#alert-name', contactForm).show();
        setTimeout(function () {
          $('input#name', contactForm).removeClass('is-invalid');
          $('#alert-name', contactForm).text('');
          $('#alert-name', contactForm).hide();
        }, 5000);
      }

      // Email
      if (response.success === false && response.data.email) {
        $('input#email', contactForm).addClass('is-invalid');
        $('#alert-email', contactForm).text(response.data.email);

        $('#alert-email', contactForm).show();
        setTimeout(function () {
          $('input#email', contactForm).removeClass('is-invalid');
          $('#alert-email', contactForm).text('');
          $('#alert-email', contactForm).hide();
        }, 5000);
      }

      // Phone
      if (response.success === false && response.data.phone) {
        $('input#phone', contactForm).addClass('is-invalid');
        $('#alert-phone', contactForm).text(response.data.phone);

        $('#alert-phone', contactForm).show();
        setTimeout(function () {
          $('input#phone', contactForm).removeClass('is-invalid');
          $('#alert-phone', contactForm).text('');
          $('#alert-phone', contactForm).hide();
        }, 5000);
      }

      // Captcha
      // if (response.success === false && response.data.captcha) {
      //   $('input#captcha', contactForm).addClass('is-invalid');
      //   $('#alert-captcha', contactForm).text(response.data.captcha);

      //   $('#alert-captcha', contactForm).show();
      //   setTimeout(function () {
      //     $('input#captcha', contactForm).removeClass('is-invalid');
      //     $('#alert-captcha', contactForm).text('');
      //     $('#alert-captcha', contactForm).hide();
      //   }, 5000);
      // }

      // Message
      if (response.success === false && response.data.message) {
        $('textarea#message', contactForm).addClass('is-invalid');
        $('#alert-message', contactForm).text(response.data.message);

        $('#alert-message', contactForm).show();
        setTimeout(function () {
          $('textarea#message', contactForm).removeClass('is-invalid');
          $('#alert-message', contactForm).text('');
          $('#alert-message', contactForm).hide();
        }, 5000);
      }
    },

    error: function (response) {
      console.error(response);

      // Reset submit button
      contactFormSubmitButton.attr('disabled', false);

      $('#alert-submission-failed', contactForm).show();
      setTimeout(function () { $('#alert-submission-failed', contactForm).hide() }, 5000);

      document.getElementById('alert-submission-failed', contactForm).scrollIntoView();
    },
  });

})(jQuery);
