/**
 * KYC Form v1.0.0
 */

(function ($) {

  // contact Form Page
  var kycForm = $('#kyc-form');
  var kycFormSubmitButton = kycForm.find(':submit');

  // jQuery AJAX form
  var cf = kycForm.ajaxForm({
    beforeSubmit: function (arr, $form, options) {
      var filled = arr.every(function (input) {
        return input.value !== '';
      });

      if (filled) {
        kycFormSubmitButton.attr('disabled', true);
      }
    },

    success: function (response) {
      // Reset submit button
      kycFormSubmitButton.attr('disabled', false);

      // If success
      if (response.success === true) {
        $('#alert-submission', kycForm).show();
        setTimeout(function () { $('#alert-submission', kycForm).hide() }, 5000);

        cf.clearForm();
        document.getElementById('alert-submission', kycForm).scrollIntoView();

        // Add conversion tag here.
      }

      // Bank name
      if (response.success === false && response.data.bank_name) {
        $('input#bank-name', kycForm).addClass('is-invalid');
        $('#alert-bank-name', kycForm).text(response.data.bank_name);

        $('#alert-bank-name', kycForm).show();
        setTimeout(function () {
          $('input#bank-name', kycForm).removeClass('is-invalid');
          $('#alert-bank-name', kycForm).text('');
          $('#alert-bank-name', kycForm).hide();
        }, 5000);
      }

      // Establishment year
      if (response.success === false && response.data.bank_establishment_year) {
        $('input#bank-establishment-year', kycForm).addClass('is-invalid');
        $('#alert-bank-establishment-year', kycForm).text(response.data.bank_establishment_year);

        $('#alert-bank-establishment-year', kycForm).show();
        setTimeout(function () {
          $('input#bank-establishment-year', kycForm).removeClass('is-invalid');
          $('#alert-bank-establishment-year', kycForm).text('');
          $('#alert-bank-establishment-year', kycForm).hide();
        }, 5000);
      }

      // Address
      if (response.success === false && response.data.bank_address) {
        $('textarea#bank-address', kycForm).addClass('is-invalid');
        $('#alert-bank-address', kycForm).text(response.data.bank_address);

        $('#alert-bank-address', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-address', kycForm).removeClass('is-invalid');
          $('#alert-bank-address', kycForm).text('');
          $('#alert-bank-address', kycForm).hide();
        }, 5000);
      }

      // Director's name
      if (response.success === false && response.data.bank_directors_name) {
        $('input#bank-directors-name', kycForm).addClass('is-invalid');
        $('#alert-bank-directors-name', kycForm).text(response.data.bank_directors_name);

        $('#alert-bank-directors-name', kycForm).show();
        setTimeout(function () {
          $('input#bank-directors-name', kycForm).removeClass('is-invalid');
          $('#alert-bank-directors-name', kycForm).text('');
          $('#alert-bank-directors-name', kycForm).hide();
        }, 5000);
      }

      // Director's phone
      if (response.success === false && response.data.bank_directors_phone) {
        $('input#bank-directors-phone', kycForm).addClass('is-invalid');
        $('#alert-bank-directors-phone', kycForm).text(response.data.bank_directors_phone);

        $('#alert-bank-directors-phone', kycForm).show();
        setTimeout(function () {
          $('input#bank-directors-phone', kycForm).removeClass('is-invalid');
          $('#alert-bank-directors-phone', kycForm).text('');
          $('#alert-bank-directors-phone', kycForm).hide();
        }, 5000);
      }

      // Members
      if (response.success === false && response.data.bank_members) {
        $('textarea#bank-members', kycForm).addClass('is-invalid');
        $('#alert-bank-members', kycForm).text(response.data.bank_members);

        $('#alert-bank-members', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-members', kycForm).removeClass('is-invalid');
          $('#alert-bank-members', kycForm).text('');
          $('#alert-bank-members', kycForm).hide();
        }, 5000);
      }

      // Board members
      if (response.success === false && response.data.bank_board_members) {
        $('textarea#bank-board-members', kycForm).addClass('is-invalid');
        $('#alert-bank-board-members', kycForm).text(response.data.bank_board_members);

        $('#alert-bank-board-members', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-board-members', kycForm).removeClass('is-invalid');
          $('#alert-bank-board-members', kycForm).text('');
          $('#alert-bank-board-members', kycForm).hide();
        }, 5000);
      }

      // Deposits
      if (response.success === false && response.data.bank_deposits) {
        $('textarea#bank-deposits', kycForm).addClass('is-invalid');
        $('#alert-bank-deposits', kycForm).text(response.data.bank_deposits);

        $('#alert-bank-deposits', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-deposits', kycForm).removeClass('is-invalid');
          $('#alert-bank-deposits', kycForm).text('');
          $('#alert-bank-deposits', kycForm).hide();
        }, 5000);
      }

      // Loan disbursements
      if (response.success === false && response.data.bank_loan_disbursements) {
        $('textarea#bank-loan-disbursements', kycForm).addClass('is-invalid');
        $('#alert-bank-loan-disbursements', kycForm).text(response.data.bank_loan_disbursements);

        $('#alert-bank-loan-disbursements', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-loan-disbursements', kycForm).removeClass('is-invalid');
          $('#alert-bank-loan-disbursements', kycForm).text('');
          $('#alert-bank-loan-disbursements', kycForm).hide();
        }, 5000);
      }

      // NPA reduction efforts
      if (response.success === false && response.data.bank_npa_reduction_efforts) {
        $('textarea#bank-npa-reduction-efforts', kycForm).addClass('is-invalid');
        $('#alert-bank-npa-reduction-efforts', kycForm).text(response.data.bank_npa_reduction_efforts);

        $('#alert-bank-npa-reduction-efforts', kycForm).show();
        setTimeout(function () {
          $('textarea#bank-npa-reduction-efforts', kycForm).removeClass('is-invalid');
          $('#alert-bank-npa-reduction-efforts', kycForm).text('');
          $('#alert-bank-npa-reduction-efforts', kycForm).hide();
        }, 5000);
      }
    },

    error: function (response) {
      console.error(response);

      // Reset submit button
      kycFormSubmitButton.attr('disabled', false);

      $('#alert-submission-failed', kycForm).show();
      setTimeout(function () { $('#alert-submission-failed', kycForm).hide() }, 5000);

      document.getElementById('alert-submission-failed', kycForm).scrollIntoView();
    },
  });

})(jQuery);
