/**
 * Job Application Form v1.0.0
 */

(function ($) {

  // Job Application Form Page
  var applicationForm = $('#application-form');
  var applicationFormSubmitButton = applicationForm.find(':submit');

  // jQuery AJAX form
  var cf = applicationForm.ajaxForm({
    beforeSubmit: function (arr, $form, options) {
      var filled = arr.every(function (input) {
        return input.value !== '';
      });

      if (filled) {
        applicationFormSubmitButton.attr('disabled', true);
      }
    },

    success: function (response) {
      // Reset submit button
      applicationFormSubmitButton.attr('disabled', false);

      // If success
      if (response.success === true) {
        $('#alert-submission', applicationForm).show();
        setTimeout(function () { $('#alert-submission', applicationForm).hide() }, 5000);

        cf.clearForm();
        document.getElementById('alert-submission', applicationForm).scrollIntoView();

        // Add conversion tag here.
      }

      // Name
      if (response.success === false && response.data.name) {
        $('input#name', applicationForm).addClass('is-invalid');
        $('#alert-name', applicationForm).text(response.data.name);

        $('#alert-name', applicationForm).show();
        setTimeout(function () {
          $('input#name', applicationForm).removeClass('is-invalid');
          $('#alert-name', applicationForm).text('');
          $('#alert-name', applicationForm).hide();
        }, 5000);
      }

      // Address
      if (response.success === false && response.data.address) {
        $('input#address', applicationForm).addClass('is-invalid');
        $('#alert-address', applicationForm).text(response.data.address);

        $('#alert-address', applicationForm).show();
        setTimeout(function () {
          $('input#address', applicationForm).removeClass('is-invalid');
          $('#alert-address', applicationForm).text('');
          $('#alert-address', applicationForm).hide();
        }, 5000);
      }

      // Email
      if (response.success === false && response.data.email) {
        $('input#email', applicationForm).addClass('is-invalid');
        $('#alert-email', applicationForm).text(response.data.email);

        $('#alert-email', applicationForm).show();
        setTimeout(function () {
          $('input#email', applicationForm).removeClass('is-invalid');
          $('#alert-email', applicationForm).text('');
          $('#alert-email', applicationForm).hide();
        }, 5000);
      }

      // Phone
      if (response.success === false && response.data.phone) {
        $('input#phone', applicationForm).addClass('is-invalid');
        $('#alert-phone', applicationForm).text(response.data.phone);

        $('#alert-phone', applicationForm).show();
        setTimeout(function () {
          $('input#phone', applicationForm).removeClass('is-invalid');
          $('#alert-phone', applicationForm).text('');
          $('#alert-phone', applicationForm).hide();
        }, 5000);
      }

      // Position
      if (response.success === false && response.data.position) {
        $('select#position', applicationForm).addClass('is-invalid');
        $('#alert-position', applicationForm).text(response.data.position);

        $('#alert-position', applicationForm).show();
        setTimeout(function () {
          $('select#position', applicationForm).removeClass('is-invalid');
          $('#alert-position', applicationForm).text('');
          $('#alert-position', applicationForm).hide();
        }, 5000);
      }

      // CV
      if (response.success === false && response.data.cv) {
        $('input#cv', applicationForm).addClass('is-invalid');
        $('#alert-cv', applicationForm).text(response.data.cv);

        $('#alert-cv', applicationForm).show();
        setTimeout(function () {
          $('input#cv', applicationForm).removeClass('is-invalid');
          $('#alert-cv', applicationForm).text('');
          $('#alert-cv', applicationForm).hide();
        }, 5000);
      }

      // Captcha
      // if (response.success === false && response.data.captcha) {
      //   $('input#captcha', applicationForm).addClass('is-invalid');
      //   $('#alert-captcha', applicationForm).text(response.data.captcha);

      //   $('#alert-captcha', applicationForm).show();
      //   setTimeout(function () {
      //     $('input#captcha', applicationForm).removeClass('is-invalid');
      //     $('#alert-captcha', applicationForm).text('');
      //     $('#alert-captcha', applicationForm).hide();
      //   }, 5000);
      // }

      // Details
      if (response.success === false && response.data.details) {
        $('textarea#details', applicationForm).addClass('is-invalid');
        $('#alert-details', applicationForm).text(response.data.details);

        $('#alert-details', applicationForm).show();
        setTimeout(function () {
          $('textarea#details', applicationForm).removeClass('is-invalid');
          $('#alert-details', applicationForm).text('');
          $('#alert-details', applicationForm).hide();
        }, 5000);
      }
    },

    error: function (response) {
      console.error(response);

      // Reset submit button
      applicationFormSubmitButton.attr('disabled', false);

      $('#alert-submission-failed', applicationForm).show();
      setTimeout(function () { $('#alert-submission-failed', applicationForm).hide() }, 5000);

      document.getElementById('alert-submission-failed', applicationForm).scrollIntoView();
    },
  });

})(jQuery);
