import './owl.carousel';

/**
 * Carousels
 */
(function ($) {

  // Testimonials
  const testimonialCarousel = $('#testimonial-carousel');
  testimonialCarousel.owlCarousel({
    autoplay: true,
    loop: true,
    margin: 32,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
    }
  });

  // Clients
  const clientCarousel = $('#client-carousel');
  clientCarousel.owlCarousel({
    autoplay: true,
    loop: true,
    margin: 15,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 4,
      },
      992: {
        items: 6,
      }
    }
  });

})(jQuery);
